import React, { useEffect, useState } from 'react';
import { Link, graphql } from 'gatsby';
import queryString from 'query-string';
import axios from 'axios';
import { withPrismicPreview } from 'gatsby-plugin-prismic-previews';
import { Button, Container } from 'react-bootstrap';
import firebase from 'firebase';
import { linkResolver } from '../utils/LinkResolver';

import { Layout } from '../components/Layout';
import { Seo } from '../components/Seo';
import { defaultLanguage } from '../../prismic-configuration';

function SuccessPage({ location, data }) {
  const [status, setStatus] = useState('pending');
  const [message, setMessage] = useState('');
  const [requestID, setRequestID] = useState('');
  const [sessionId, setSessionId] = useState('');
  const [isSecondPayment, setIsSecondPayment] = useState(false);
  const [currentUserProfileData, setCurrentUserProfileData] = useState([]);

  const isBrowser = typeof window !== 'undefined';

  // fetch if exist Firebase firestore(database) data
  useEffect(() => {
    if (isBrowser) {
      try {
        firebase
          // Access your Firestore database
          .firestore()
          // Access the "users" collection
          .collection('usersCollection')

          .onSnapshot(snapshot => {
            const listUsers = snapshot.docs.map(doc => ({
              id: doc.id,
              ...doc.data(),
            }));

            const usersPersonalData = listUsers.filter(
              userFromList => userFromList.uid === firebase.auth().currentUser?.uid,
            );
            setCurrentUserProfileData(usersPersonalData[0]);
          });
      } catch (error) {
        console.error(error);
      }
    }
  }, []);

  // get params
  useEffect(() => {
    const params = queryString.parse(location.search);

    if (params.session_id) {
      setSessionId(params.session_id);
      setIsSecondPayment(params.secondPayment);
    } else {
      setStatus('failed');
      setMessage('Missing needed information, did you come here after paying?');
    }
  }, [location.search]);

  useEffect(() => {
    if (!sessionId) return;
    let isMounted = true;

    if (isMounted) {
      const getFirstPaymentApplicant = async () => {
        try {
          const result = await axios.get('/api/personal-application-fee', {
            /// //////////////////////////////////////
            params: { session_id: sessionId },
          });
          setStatus('fulfilled');
          setMessage(result.data.message);
          setRequestID(result.data.requestID);
        } catch (error) {
          setStatus('failed');
          setMessage(error.response?.data?.message || error.message);
        }
      };
      const getSecondPaymentApplicant = async () => {
        try {
          const result = await axios.get('/api/personal-application-fee-registration-and-card', {
            params: {
              session_id: sessionId,
              isSecondPayment,
            },
          });
          setStatus('fulfilled');
          setMessage(result.data.message);
          setRequestID(result.data.requestID);
        } catch (error) {
          setStatus('failed');
          setMessage(error.response?.data?.message || error.message);
        }
      };
      if (!isSecondPayment) {
        getFirstPaymentApplicant();
      } else if (isSecondPayment) {
        getSecondPaymentApplicant();
      } else {
        return;
      }
    }
    return () => {
      isMounted = false;
    };
    // Grab the session
  }, [sessionId]);

  let firestore = {};
  // Can be sending to mush requests to the db. Check
  // Possible place of creation of multiple user profiles. ++
  if (isBrowser && currentUserProfileData.id && !currentUserProfileData.requestID) {
    firestore = firebase.firestore();
    try {
      firestore.collection('usersCollection').doc(currentUserProfileData.id).update({
        requestID,
      });
    } catch (error) {
      console.error(error);
    }
  }

  if (!data) return null;

  const pageContent = data.prismicPage;
  const page = pageContent.data || {};

  const { lang, type, url } = pageContent;
  const alternateLanguages = pageContent.alternate_languages || [];
  const activeDoc = {
    lang,
    type,
    url,
    alternateLanguages,
  };
  const langResolver = lang === defaultLanguage ? '' : `/${lang}`;
  const topMenu = data.prismicMenuLink || {};
  return (
    <Layout topMenu={topMenu.data} activeDocMeta={activeDoc}>
      <Seo title={page.page_title.text} lang={lang} />
      <Container className="container-success">
        {status === 'fulfilled' && (
          <h1 className="page-title">
            <i className="fa fa-check-circle text-success" />
            {page.page_title.text}
          </h1>
        )}
        {status === 'pending' && (
          <h1 className="page-title">
            <i className="fa fa-check-circle text-warning" />
            Checking...
          </h1>
        )}
        {status === 'failed' && (
          <h1 className="page-title">
            <i className="fa fa-times text-danger" />
            Ooops...
          </h1>
        )}
        <div className="">
          <div className="success-message-wrapper">
            {status === 'pending' && (
              <div>
                <div>Fetching your data...</div>
                <span>If you see this message more than 10 seconds, please refresh the page</span>
              </div>
            )}
            {status === 'failed' && (
              <div>It seems something went wrong. Please check you email for more information</div>
            )}
            {status === 'fulfilled' && <div>Thank you!</div>}
            {message && <div dangerouslySetInnerHTML={{ __html: message }} />}
            <div className="buttons-wrapper">
              <Link className="success-btns" to={`${langResolver}/`}>
                <Button variant="outline-success">← Home Page</Button>
              </Link>
              <Link className="success-btns" to={`${langResolver}/personal-page`}>
                <Button variant="outline-success">Personal Area →</Button>
              </Link>
            </div>
          </div>
        </div>
      </Container>
    </Layout>
  );
}

export const query = graphql`
  query P_SuccessPageQuery($id: String, $lang: String) {
    prismicPage(id: { eq: $id }, lang: { eq: $lang }) {
      _previewable
      type
      url
      lang
      alternate_languages {
        id
        type
        lang
        uid
      }
      data {
        page_title {
          text
        }
        body {
          ... on PrismicSliceType {
            slice_type
          }
          ...PageDataBodyTable
          ...PageDataBodyText
          ...PageDataBodyQuote
          ...PageDataBodyFullWidthImage
          ...PageDataBodyImageGallery
          ...PageDataBodyImageHighlight
        }
      }
    }
    prismicMenuLink(lang: { eq: $lang }) {
      ...MenuLinkFragment
    }
  }
`;

export default withPrismicPreview(SuccessPage, [
  {
    repositoryName: process.env.GATSBY_PRISMIC_REPO_NAME,
    linkResolver,
  },
]);
